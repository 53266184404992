<template>
  <b-card>
    <!-- {{ assigned_agents }} -->
    <assigned-staff :assigned_staff="assigned_agents" />
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import AssignedStaff from "./hepers/AssignedStaff.vue";
import CounselorServices from "@/apiServices/CounselorServices";

export default {
  components: {
    BCard,
    AssignedStaff,
  },
  data() {
    return {
      assigned_agents: [],
    };
  },
  methods: {
    async getAssignedAgents() {
      try {
        const response = await CounselorServices.getAssignedAgents();
        // console.log(response.data);
        if (response.data.status) {
          this.assigned_agents = response.data.data;
        }
      } catch (err) {
        console.log("Error in getting  ", err);
      }
    },
  },
  beforeMount() {
    this.getAssignedAgents();
  },
};
</script>

<style>
</style>